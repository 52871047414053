// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/fonts/Agustina.woff");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./assets/fonts/Montserrat-Regular.ttf");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "@font-face {\n  font-family: \"Agustina Regular\";\n  font-style: normal;\n  font-weight: normal;\n  src: local(\"Agustina Regular\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff\");\n  font-display: swap;\n}\n@font-face {\n  font-family: \"Montserrat\";\n  src: local(\"Montserrat\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");\n  font-display: swap;\n}\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\nhtml,\nbody {\n  scroll-behavior: smooth;\n}\nbody {\n  margin: 0;\n  font: 19px / 23px Montserrat, \"Montserrat\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n/* Media Query */\n@media (max-width: 1380px) {\n  html,\n  body {\n    font-size: 16px;\n    line-height: normal;\n  }\n}\n", ""]);
// Exports
module.exports = exports;
